<template>
  <template v-if="!Util.isEmpty(Items)">
    <DataTable :id="ID" class="table-hover table-lg" :options="Options">
      <thead>
        <tr class="nk-tb-item nk-tb-head size14">
          <th class="nk-tb-col py-4" style="min-width: 180px">Name</th>
          <th class="nk-tb-col py-4">Sale Price</th>
          <th class="nk-tb-col py-4 tb-col-lg">Cost Price</th>
          <th class="nk-tb-col py-4 tb-col-md">Stock</th>
          <th class="nk-tb-col py-4 tb-col-lg">SKU</th>
          <th class="nk-tb-col py-4 tb-col-lg">Unit</th>
          <th class="nk-tb-col py-4 nk-tb-col-tools text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="nk-tb-item size14" v-for="item in Items" :key="item.uuid">
          <td class="min-width200">
            <router-link
              :to="{ name: 'item-detail', params: { uuid: item.uuid } }"
              class="cursor"
            >
              <div class="user-card">
                <div class="user-avatar w3-white d-none d-sm-flex w3-border">
                  <img
                    :src="item.feature_photo_url"
                    alt=""
                    class="rounded-circle"
                  />
                </div>
                <div class="user-info">
                  <p class="size15 m-0">{{ item.name + " " }}</p>
                </div>
              </div>
            </router-link>
          </td>
          <td class="nk-tb-col" v-html="Util.Currency(item.sale_price)"></td>
          <td
            class="nk-tb-col tb-col-lg"
            v-html="Util.Currency(item.cost_price)"
          ></td>
          <td class="nk-tb-col tb-col-md">
            {{ item.stock }}
          </td>
          <td class="nk-tb-col tb-col-lg ellipsis">
            {{ item.sku }}
          </td>
          <td class="nk-tb-col tb-col-lg ellipsis">{{ item.unit }}</td>
          <td class="nk-tb-col nk-tb-col-tools">
            <ul class="nk-tb-actions gx-1">
              <li>
                <div class="drodown">
                  <a
                    href="#"
                    class="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                    ><em class="icon ni ni-more-h"></em
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="link-list-opt no-bdr">
                      <li>
                        <router-link
                          :to="{
                            name: 'item-detail',
                            params: { uuid: item.uuid },
                          }"
                          class="cursor"
                        >
                          <em class="icon ni ni-eye"></em>
                          <span>View Detail</span></router-link
                        >
                      </li>
                      <li>
                        <a
                          href="#"
                          @click.prevent="QuickEdit(item.uuid)"
                          class="cursor"
                        >
                          <em class="icon ni ni-edit"></em>
                          <span>Edit Item</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Delete Item"
                          @click.stop.prevent="DeleteDetail(item.uuid)"
                          href="#"
                        >
                          <em class="icon fas fa-trash"></em>
                          <span>Delete Item</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </DataTable>
  </template>
  <div v-else class="p-5 w3-center">
    <img src="/images/item-not-found.png" class="mb-4" />
    <h4>No Item was found</h4>
    <a
      href="#"
      :title="'Add new ' + (itemType ?? 'item')"
      class="btn btn-white btn-dim btn-outline-light"
      @click.prevent="AddItem = true"
    >
      <em class="dd-indc icon ni ni-edit"></em>
      <span class="d-none d-md-inline">Add a new {{ itemType ?? "item" }}</span>
      <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
    </a>
  </div>
  <AddItem
    :toggle-by="AddItem"
    @oncancel="AddItem = false"
    @onsave="AddItem = false"
    :title="'Add new ' + (itemType ?? 'item')"
  ></AddItem>
  <Aside
    :toggle-by="DoEdit"
    @oncancel="OnCancel"
    cancel-text="Cancel"
    width="1024px"
  >
    <ItemUpdate
      v-if="DoEdit"
      :item="item"
      @onsave="OnSave"
      @oncancel="OnCancel"
      cancel-text="Cancel"
    />
  </Aside>
</template>
<script>
import { ref, toRef } from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Util } from "@/helpers/utilities";
import DataTable from "@/components/ui/DataTable.vue";
import Aside from "@/components/ui/Aside.vue";
import ItemUpdate from "@/components/ui/item/ItemUpdate.vue";
import AddItem from "@/components/ui/item/AddItem.vue";

export default {
  components: {
    DataTable,
    Aside,
    AddItem,
    ItemUpdate,
  },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
    itemType: {
      type: String,
    },
  },

  setup(props) {
    const store = useStore();
    const ID = props.id ? props.id : uuidv4();
    const Items = toRef(props, "items");
    const Options = {
      pageLength: 50,
      responsive: false,
      order: [[0, "asc"]],
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1],
        },
      ],
    };
    const DeleteItem = (uuid) => {
      console.log("uuid.value", uuid);
    };
    const DoEdit = ref(false);
    const AddItem = ref(false);
    const item = ref({});
    const QuickEdit = async (uuid) => {
      await store.dispatch("items/getItem", uuid);
      const _item = store.state.items.item;
      item.value = {
        ..._item,
      };
      DoEdit.value = true;
    };
    const OnCancel = () => {
      item.value = {};
      DoEdit.value = false;
    };
    const OnSave = () => {
      OnCancel();
    };
    return {
      store,
      ID,
      QuickEdit,
      OnCancel,
      OnSave,
      DeleteItem,
      Items,
      item,
      Util,
      Options,
      DoEdit,
      AddItem,
    };
  },
};
</script>