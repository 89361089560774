<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      :title="channel ? ProductType + ' for ' + channel : ProductType"
      :description="
        'Manage your ' +
        (channel ? ProductType + ' for ' + channel : ProductType)
      "
    >
      <ul class="nk-block-tools g-1">
        <li class="nk-block-tools-opt">
          <a
            href="#"
            :title="'Add a new + ProductTypeSingular'"
            class="btn btn-white btn-dim btn-outline-light"
            @click.prevent="Toggle = true"
          >
            <em class="dd-indc icon ni ni-plus"></em>
            <span class="d-inline">New {{ ProductTypeSingular }}</span>
            <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
          </a>
          <AddItem
            :toggle-by="Toggle"
            @oncancel="SaveOnNew"
            @onsave="SaveOnNew"
            :title="'Add new ' + (itemType ?? 'item')"
            :item-def="{
              itemType: itemType,
              channel: channel,
              ProductType: ProductType,
            }"
          ></AddItem>
        </li>
      </ul>
    </BasePageHeader>
    <div class="card-inner">
      <div class="px-1">
        <ItemList id="itemList" :items="Items" :item-type="itemType" />
      </div>
    </div>
  </BasePageContent>
</template>

<script>
import { ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import ItemList from "@/components/ui/item/ItemList.vue";
import AddItem from "@/components/ui/item/AddItem.vue";
import { Util } from "@/helpers/utilities";

import { useStore } from "vuex";
export default {
  components: {
    ItemList,
    AddItem,
  },
  props: {
    channel: {
      type: String,
    },
    itemType: {
      type: String,
    },
    addNew: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const Items = ref([]);
    const ProductType = ref("Items");
    const ProductTypeSingular = ref("Item");
    const AllItems = computed(() => store.getters["items/items"]);
    let referral = "items";
    let pType = "";
    watchEffect(() => {
      Items.value = AllItems.value;
      ProductType.value = "Inventory";
      ProductTypeSingular.value = "Item";

      if (typeof props.channel !== "undefined") {
        if (props.channel === "sale") {
          pType = "sale";
          Items.value = Items.value.filter((i) => i.is_sale);
        } else if (props.channel === "purchase") {
          pType = "purchase";
          Items.value = Items.value.filter((i) => i.is_purchase);
        }
      }

      if (typeof props.itemType !== "undefined") {
        if (props.itemType === "service") {
          referral = "services";
          ProductType.value = "Services";
          ProductTypeSingular.value = "Service";
          Items.value = Items.value.filter((i) => i.type === "service");
        } else if (props.itemType === "item") {
          referral = "products";
          ProductType.value = "Products";
          ProductTypeSingular.value = "Product";
          Items.value = Items.value.filter((i) => i.type === "item");
        }
      }
    });
    const Toggle = ref(props.addNew);
    const router = useRouter();
    const SaveOnNew = () => {
      if (props.addNew) {
        router.push({
          name:
            referral === "items"
              ? "items"
              : pType
              ? referral + "-" + pType
              : referral,
        });
      } else {
        Toggle.value = false;
      }
    };
    return {
      AllItems,
      Toggle,
      SaveOnNew,
      Util,
      Items,
      ProductType,
      ProductTypeSingular,
    };
  },
};
</script>